<template>
  <div>
    <slot :toggle="toggle" :expanded="isExpanded" />

    <transition-collapse>
      <div v-show="isExpanded">
        <slot name="submenu" />
      </div>
    </transition-collapse>
  </div>
</template>

<script>
import TransitionCollapse from './TransitionCollapse.vue';

export default {
  components: { TransitionCollapse },

  props: {
    expanded: { required: false, type: Boolean, default: false }
  },

  data() {
    return {
      isExpanded: this.expanded
    };
  },

  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>
