<template>
  <div>
    <slot
      :isMobileMenuOpen="isMobileMenuOpen"
      :isMobileSearchOpen="isMobileSearchOpen"
      :toggleMobileMenu="toggleMobileMenu"
      :toggleMobileSearch="toggleMobileSearch"
    />
  </div>
</template>

<script>
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';
import throttle from 'lodash/throttle';

const fullConfig = resolveConfig(tailwindConfig);

export default {
  data() {
    return {
      isMobileMenuOpen: false,
      isMobileSearchOpen: false
    };
  },

  watch: {
    isMobileMenuOpen(value) {
      if (value) {
        // TODO: lock body scroll
      } else {
        // TODO: unlock body scroll
      }
    }
  },

  mounted() {
    window.addEventListener('resize', this.onResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
      this.isMobileSearchOpen = false;
    },

    toggleMobileSearch() {
      this.isMobileSearchOpen = !this.isMobileSearchOpen;
      this.isMobileMenuOpen = false;
    },

    onResize: throttle(function() {
      if (window.screen.availWidth >= parseInt(fullConfig.theme.screens.lg, 10)) {
        this.isMobileMenuOpen = false;
        this.isMobileSearchOpen = false;
      }
    }, 300)
  }
};
</script>
