import Vue from 'vue';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import '../admin/public/bootstrap';
import LoadScript from '@common/components/LoadScript.vue';
import Navigation from '../application/javascripts/Navigation';
import SideMenuItem from '../application/javascripts/SideMenuItem';
import VueTippy, { TippyComponent } from 'vue-tippy';

require('../styles/application.scss');

// Add all images from the "images" folder to the pipeline
require.context('../application/images', true);

Rails.start();
Turbolinks.start();
require.context('../application/images', true);

// Import all images <%= image_pack_tag 'rails.png' %>
require.context('../application/images', true);

Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);
Vue.component('load-script', LoadScript);
Vue.component('navigation', Navigation);
Vue.component('side-menu-item', SideMenuItem);
Vue.component('read-more', () => import('../application/javascripts/ReadMore'));

let vueApp;

// Bootstrap the Vue root instance
document.addEventListener('turbolinks:load', () => {
  vueApp = new Vue({ el: '#js-wrapper' });
});

document.addEventListener('turbolinks:visit', () => {
  if (vueApp) vueApp.$destroy();
});
